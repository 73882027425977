














import Vue from "vue";
import userManager from "@/services/userManager";
import StatisticsPage from "@/components/StatisticsPage.vue";

export default Vue.extend({
  props: ["label", "user"],
  components: { StatisticsPage },
  data() {
    return {
      userManager,
    };
  },
});
