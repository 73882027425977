





























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import ProductDaysOfMonth from "../partials/ProductDaysOfMonth.vue";
import DetailTelesale from "../partials/DetailTelesale.vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  components: {
    ProductDaysOfMonth,
    DetailTelesale,
  },
  data() {
    return {
      userManager,
      user: null,
    };
  },
  computed: {
    label() {
      if (!this.user) {
        return "";
      }
      return `Thống kê nhân viên: ${this.user.name}`;
    },
  },
  async created() {
    this.user = await coreApiClient.call("users", "findById", {
      id: this.$route.params.userId,
    });
  },
});
