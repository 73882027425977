var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('div',[(_vm.user.roles.includes('telesale') && _vm.userManager.checkRole(['admin', 'kt', 'qlk', 'vd', 'vdl', 'telesale']))?_c('detail-telesale',{attrs:{"user":_vm.user,"label":_vm.label}}):(
        _vm.user.roles &&
        (_vm.user.roles.includes('mkt') ||
          _vm.user.roles.includes('rd') ||
          _vm.user.roles.includes('rdl') ||
          _vm.user.roles.includes('design') ||
          _vm.user.roles.includes('content'))
      )?_c('product-days-of-month',{attrs:{"user":_vm.user,"label":_vm.label}}):_c('div',[_vm._v(" Không có thống kê phù hợp cho user "),_c('b',[_vm._v(_vm._s(_vm.user.name))])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }